import { useState } from 'react';
import tiktokApi from '../../api/tiktokApi';
import Input from '../Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSearch } from '@fortawesome/free-solid-svg-icons';
import ErrorPopup from '../Modal/popup/ErrorPopup';
import SuccessPopup from '../Modal/popup/SuccessPopup';
import Modal from '../Modal';
import handleErrorAsync from '../../utils/handleErrorAsync';
import shopeApi from '../../api/shopeeApi';

function FindOrderMini({ logo, title, description, merchant }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [input, setInput] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [modalType, setModalType] = useState(2);
    const [openModal, setOpenModal] = useState(false);

    const onFindOrder = async () => {
        if (!input) return;
        setisLoading(true);
        setModalType(1);
        try {
            switch (merchant) {
                case 'tiktok':
                    await tiktokApi.findOrder(input);
                    break;
                case 'shopee':
                    await shopeApi.findOrder(input);
                    break;
                default:
                    break;
            }
            setModalType(2);
        } catch (error) {
            console.log(error);
            setModalType(3);
            handleErrorAsync(error, setErrorMsg);
        }
        setisLoading(false);
        setOpenModal(true);
    };

    return (
        <div className="flex flex-col justify-between">
            <div className="w-full flex justify-center gap-2">
                <div className="w-full h-full">
                    <Input
                        setInput={setInput}
                        isClearable={true}
                        type="text"
                        className="w-full p-3 border rounded-md border-primary-500 bg-transparent bg-white"
                        placeholder="Nhập mã đơn Video"
                        input={input}
                    />
                </div>
                <button
                    disabled={isLoading}
                    onClick={onFindOrder}
                    className="p-3 w-20 shrink-0 bg-primary-600 text-white rounded-lg disabled:opacity-60"
                >
                    {isLoading ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="animate-spin"
                        />
                    ) : (
                        <FontAwesomeIcon icon={faSearch} />
                    )}
                </button>
            </div>

            {/* <div className="p-2">
                <Footer />
            </div> */}
            <Modal isOpen={openModal}>
                {modalType === 3 && (
                    <ErrorPopup setModal={setOpenModal} errorMsg={errorMsg} />
                )}
                {modalType === 2 && (
                    <SuccessPopup
                        setModal={setOpenModal}
                        successMsg={'Thêm đơn thành công'}
                    />
                )}
            </Modal>
        </div>
    );
}

export default FindOrderMini;
