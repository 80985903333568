import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import postApi from '../../api/postApi';
import AnswerQuestion from '../../components/Q&A/Q&A';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import FindOrderMini from '../../components/Ecommerce/FindOrderMini';

function VideoList() {
    const { userInfo } = useSelector((state) => state.auth);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [removeCatId, setRemoveCatId] = useState();
    const [videoList, setVideoList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchVideo, setSearchVideo] = useState('');
    const [mainVideos, setMainVideos] = useState([]);
    const [input, setInput] = useState('');
    const fetchVideoList = async (categoryId, searchTerm) => {
        try {
            const { data } = await postApi.getVideoList(
                `categoryId=${categoryId}&productName=${searchTerm}`
            );
            setVideoList(data.videos);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchVideoCategories = async () => {
        try {
            const { data } = await postApi.getVideoCategories();
            setCategories(
                data.videoCategories.map((el) => ({ ...el, isOpen: false }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onOpenCateogry = async (categoryId) => {
        fetchVideoList(categoryId, searchTerm);
        setCategoryId(categoryId);
        setCategories((prev) =>
            prev.map((el) => ({
                ...el,
                isOpen: el.categoryId === categoryId,
            }))
        );
    };

    const onSearchVideo = async () => {
        if (!searchVideo) return;
        try {
            const { data } = await postApi.getVideoList(
                `productName=${searchVideo}`
            );
            console.log(data);
            setMainVideos(data.videos);
        } catch (error) {
            console.log(error);
        }
    };

    const onDeleteVideoList = async (videoId) => {
        try {
            await postApi.deleteVideoList(videoId);
            await fetchVideoList();
        } catch (error) {
            console.log(error);
        }
    };

    const onSetRemoveCatId = async (categoryId) => {
        setRemoveCatId(categoryId);
        setIsOpenModal(true);
    };

    const onDeleteVideoCate = async () => {
        try {
            await postApi.deleteVideoCategory(removeCatId);
            await fetchVideoCategories();
        } catch (error) {
            console.log(error);
        }
        setIsOpenModal(false);
    };

    useEffect(() => {
        fetchVideoCategories();
    }, []);

    let typingTimer;
    const delayActionTime = 800;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            setSearchTerm(e.target.value);
        }, delayActionTime);
    };

    useEffect(() => {
        if (!searchTerm) setInput('');
        if (!categoryId) return;
        fetchVideoList(categoryId, searchTerm);
    }, [searchTerm, categoryId]);

    return (
        <div>
            <Header />
            <div className="p-4 flex flex-col gap-2 pt-16">
                <div>
                    <div className="flex justify-center items-center">
                        <div className="h-20">
                            <img
                                src="/img/ui/shopeevideo.png"
                                alt="shopeevideo"
                                className="h-full"
                            />
                        </div>
                        <span className="font-bold text-xl text-orange-600">
                            Shopee Video
                        </span>
                    </div>
                    <p className="text-center font-semibold">
                        Mã giảm giá đến 40% + Hoàn tiền đến 30%
                    </p>
                </div>
                <div className="pt-4">
                    <div className="flex justify-between gap-2 pb-4">
                        <div className="w-full">
                            <input
                                onChange={(e) => setSearchVideo(e.target.value)}
                                type="text"
                                name="search"
                                placeholder="Nhập tên sản phẩm"
                                className="p-3 w-full rounded-md border border-orange-500"
                            />
                        </div>
                        <button
                            onClick={onSearchVideo}
                            className="p-3 bg-primary-600 text-white w-20 rounded-md shrink-0"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                    {mainVideos.length > 0 && (
                        <div className="mb-6">
                            {mainVideos.map((video, index) => (
                                <div
                                    className="flex p-2 bg-white last:rounded-b-md justify-between border-b"
                                    key={video.id}
                                >
                                    <div className="flex">
                                        <span>#{index + 1}</span>
                                        <span className="pl-2 line-clamp-1">
                                            {video.productName}
                                        </span>
                                    </div>
                                    <Link
                                        to={video.url}
                                        target="_blank"
                                        className="underline text-orange-600 font-semibold"
                                    >
                                        Xem Video
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex gap-2 items-center py-2">
                        <div
                            className={`h-10 aspect-square bg-gradient-to-br p-2 from-orange-500
                    to-orange-600 rounded-full`}
                        >
                            <img
                                src="/img/ui/information_white.svg"
                                alt="infomation"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <h3 className="font-bold">
                            HƯỚNG DẪN CÁCH TÌM KIẾM SẢN PHẨM
                        </h3>
                    </div>
                    <div>
                        <div className=" bg-white border rounded-lg text-sm">
                            <div className="flex p-2 border-b last:border-none">
                                <p className="">
                                    Gõ tìm kiếm tên sản phẩm hoặc tên thương
                                    hiệu bạn cần mua. Ví dụ: Nước tẩy trang hoặc
                                    L'Oreal
                                </p>
                            </div>
                            <div className="flex p-2 border-b last:border-none">
                                <p className="">
                                    Voucher độc quyền chỉ áp dụng duy nhất tại
                                    kênh video{' '}
                                    <Link
                                        target="_blank"
                                        className="underline text-orange-600 font-bold"
                                        to={
                                            'https://vn.shp.ee/kr8fcxe?smtt=0.0.9'
                                        }
                                    >
                                        Longhousee
                                    </Link>
                                </p>
                            </div>
                            <div className="flex p-2 border-b last:border-none">
                                <p className="">
                                    Ưu đãi độc quyền, mua hàng tại kênh video
                                    Longhousee được hoàn tiền lên tới 30%. Chi
                                    tiết xem{' '}
                                    <Link
                                        to={'/post/16'}
                                        className="underline text-orange-600 font-bold"
                                        target="_blank"
                                    >
                                        Tại đây
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="font-bold">Danh sách ngành hàng</h4>
                <div className="flex flex-col gap-1 pb-5">
                    {categories.map((el) => (
                        <div
                            key={el.categoryId}
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenCateogry(el.categoryId);
                            }}
                            className={`p-2 shadow-md rounded-md bg-white ${
                                el.isOpen && 'shadow-orange-400 border'
                            }`}
                        >
                            <span>
                                🔥 {el.categoryName}{' '}
                                {userInfo && userInfo.role === 'admin' && (
                                    <FontAwesomeIcon
                                        onClick={() =>
                                            onSetRemoveCatId(el.categoryId)
                                        }
                                        className="pl-2 hover:text-orange-600"
                                        icon={faTrash}
                                    />
                                )}
                            </span>
                            {el.isOpen && (
                                <div onClick={(e) => e.stopPropagation()}>
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm"
                                        value={input}
                                        onChange={(e) =>
                                            setInput(e.target.value)
                                        }
                                        onKeyUp={handleTypingDone}
                                        className="p-1 border rounded-md focus-within:border focus-within:border-primary-400"
                                    />
                                    {videoList.map((el) => (
                                        <div
                                            key={el.id}
                                            className="p-2 odd:bg-slate-100 rounded-md text-sm grid grid-cols-2 text-left"
                                        >
                                            <span className="line-clamp-2">
                                                {el.productName}{' '}
                                                {userInfo &&
                                                    userInfo.role ===
                                                        'admin' && (
                                                        <FontAwesomeIcon
                                                            onClick={() =>
                                                                onDeleteVideoList(
                                                                    el.id
                                                                )
                                                            }
                                                            icon={faTrash}
                                                        />
                                                    )}
                                            </span>
                                            <Link
                                                to={el.url}
                                                className="underline"
                                            >
                                                {el.url}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div>
                    <div className="">
                        <FindOrderMini
                            merchant={'shopee'}
                            title={'Nhập mã đơn Video'}
                        />
                    </div>
                </div>

                <div>
                    <div className="flex gap-2 items-center py-2">
                        <div
                            className={`h-10 aspect-square bg-gradient-to-br p-2 from-orange-500
                    to-orange-600 rounded-full`}
                        >
                            <img
                                src="/img/ui/information_white.svg"
                                alt="infomation"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <h3 className="font-bold">
                            HƯỚNG DẪN CÁCH ÁP HOÀN TIỀN VIDEO
                        </h3>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className=" bg-white border rounded-lg text-sm">
                                <h4 className="p-2 border-b font-semibold text-orange-600">
                                    Cách 1: Mua hàng trực tiếp từ kênh Shopee
                                    Longhousee
                                </h4>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0 flex justify-center items-center p-1 border-r">
                                        <span>B1</span>
                                    </div>
                                    <p className="pl-2">
                                        Bạn tìm kiếm sản phẩm muốn mua tại mục
                                        tìm kiếm “Nhập tên sản phẩm” phía trên
                                        hoặc truy cập trực tiếp mua hàng tại
                                        kênh:{' '}
                                        <Link
                                            target="_blank"
                                            to={
                                                'https://vn.shp.ee/kr8fcxe?smtt=0.0.9'
                                            }
                                            className="underline text-orange-600"
                                        >
                                            https://vn.shp.ee/kr8fcxe?smtt=0.0.9
                                        </Link>{' '}
                                        (không cần dán link vào web)
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B2</span>
                                    </div>
                                    <p className="pl-2">
                                        Sau 48H kể từ khi thanh toán đơn hàng,
                                        bạn cần copy Mã đơn hàng và dán vào mục
                                        tìm kiếm “Nhập mã đơn Video” phía trên
                                        để thực hiện xác nhận đơn hàng.
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B3</span>
                                    </div>
                                    <p className="pl-2">
                                        Kiểm tra số tiền được hoàn{' '}
                                        <Link
                                            to={'/user-info/order'}
                                            className="underline text-primary-500 font-semibold"
                                        >
                                            TẠI ĐÂY
                                        </Link>
                                    </p>
                                </div>
                                <p className="p-2">
                                    Lưu ý: Việc xác nhận đơn do Shopee dựa vào
                                    quy định riêng để tính toán đơn hợp lệ. Vì
                                    thế vẫn có tỉ lệ 1 số đơn làm đúng quy trình
                                    vẫn không được duyệt hoặc không tìm thấy
                                    đơn.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className=" bg-white border rounded-lg text-sm">
                                <h4 className="p-2 border-b font-semibold text-orange-600">
                                    Cách 2: Mua hàng trực tiếp từ kênh video
                                    Shop bán hàng
                                </h4>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0 flex justify-center items-center p-1 border-r">
                                        <span>B1</span>
                                    </div>
                                    <p className="pl-2">
                                        Vào video của Shop thêm sản phẩm vào giỏ
                                        cần mua + copy link sản phẩm (không đặt
                                        hàng thông qua Video của KOC/KOL tích
                                        vàng, có thể xem video hướng dẫn tại
                                        đây:{' '}
                                        <Link
                                            to={
                                                'https://www.youtube.com/watch?v=UULz-Q-xDwY'
                                            }
                                            target="_blank"
                                            className="underline text-primary-600"
                                        >
                                            TẠI ĐÂY
                                        </Link>
                                        )
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B2</span>
                                    </div>
                                    <p className="pl-2">
                                        Truy cập vào
                                        <Link
                                            to={'https://longhousee.com/shopee'}
                                            className="underline text-primary-600"
                                        >
                                            https://longhousee.com/shopee
                                        </Link>{' '}
                                        và dán link sản phẩm để gắn hoàn tiền.
                                        Sau đó bấm “Mua ngay”
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B3</span>
                                    </div>
                                    <p className="pl-2">
                                        Thêm sản phẩm vào giỏ lần 2. Sau đó vào
                                        giỏ hàng điều chỉnh số lượng cần mua +
                                        Áp mã và đặt đơn
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B4</span>
                                    </div>
                                    <p className="pl-2">
                                        Sau 48 giờ kể từ khi thanh toán đơn
                                        hàng, bạn copy mã đơn hàng và dán vào
                                        mục “Tìm đơn Shopee Video”{' '}
                                        <Link
                                            className="underline text-orange-600 font-semibold"
                                            to={'/shopee'}
                                        >
                                            TẠI ĐÂY
                                        </Link>{' '}
                                        là đơn hàng sẽ hiện trên hệ thống nhé.
                                    </p>
                                </div>
                                <div className="flex p-2 border-b last:border-none">
                                    <div className="line-clamp-1 w-14 shrink-0  flex justify-center items-center p-1 border-r">
                                        <span>B5</span>
                                    </div>
                                    <p className="pl-2">
                                        Kiểm tra số tiền được hoàn{' '}
                                        <Link
                                            to={'/user-info/order'}
                                            className="underline text-primary-500 font-semibold"
                                        >
                                            TẠI ĐÂY
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AnswerQuestion postId={17} />
                <div className="pb-10"></div>
            </div>
            <Modal
                title="Xác nhận xoá Danh mục Video?"
                open={isOpenModal}
                onOk={onDeleteVideoCate}
                onCancel={() => setIsOpenModal(false)}
            />
        </div>
    );
}

export default VideoList;
