import React from 'react';
import { Link } from 'react-router-dom';

function FindButton({
    fromColorBg,
    toColorBg,
    fromColorText,
    toColorText,
    platform,
}) {
    return (
        <div className="p-2 bg-white">
            <Link
                to={platform === 'shopee' ? '/post/videolist' : '#'}
                className={`p-3 pl-0 rounded-lg w-full h-14
                     flex justify-between bg-gradient-to-r ${
                         fromColorBg || 'from-orange-400'
                     } ${toColorBg || 'to-amber-400'}`}
            >
                {platform === 'tiktok' && (
                    <div className="h-full flex justify-center items-center font-bold">
                        <img
                            src="img/brand-logo/logo-tiktok-shop-200.png"
                            alt="titkokshop"
                            className="object-cover h-12"
                        />
                        <span>TÌM ĐƠN TIKTOK</span>
                    </div>
                )}
                {platform === 'shopee' && (
                    <div className="h-full flex justify-center items-center font-bold">
                        <img
                            src="img/ui/shopeevideo.png"
                            alt="shopee"
                            className="object-cover h-14 aspect-square"
                        />
                        <span className="text-sm">TÌM ĐƠN VIDEO SHOPEE</span>
                    </div>
                )}
                <Link
                    to={'find'}
                    className={`bg-gradient-to-r ${
                        fromColorText || 'from-amber-800'
                    } ${
                        toColorText || 'to-amber-950'
                    } px-2 py-1 rounded-xl text-white font-semibold`}
                >
                    TÌM ĐƠN
                </Link>
            </Link>
        </div>
    );
}

export default FindButton;
